<template>
	<div class="single-view">
		<div class="tool-box">
			<h1>{{ ii('WHOIS_SERVICE') }}</h1>
			<div>
				<div class="tool-input-box">
					<s-text-field
							v-model="app.query.host"
							prepend-icon="globe"
							ref="tf"
							:placeholder="ii('DOMAIN_OR_IP')"
							:loading="isLoading"
							:error="error"
							@enter="checkHost"
					></s-text-field>
				</div>

				<div v-if="info" class="subpanels-box">
					<app-link
							:to="{name: 'whoisInfo', query:{host: this.app.query.host}}"
							:class="app.routeName === 'whoisInfo' ? 'active' : ''"
					>{{ ii('INFO') }}</app-link><app-link
						:to="{name: 'whoisRaw', query:{host: this.app.query.host}}"
						:class="app.routeName === 'whoisRaw' ? 'active' : ''"
					>{{ ii('RAW') }}</app-link>
				</div>

				<div v-if="app.routeName === 'whoisInfo' && info">
					<table class="result-tbl">
						<tr v-if="info.name">
							<td>{{ ii('NAME') }}:</td>
							<td>{{ info.name }}</td>
						</tr>
						<tr v-if="info.countryCode">
							<td>{{ ii('COUNTRY') }}:</td>
							<td>
								<s-flag :cc="info.countryCode.toLowerCase()"></s-flag>
								{{ info.countryName }}
							</td>
						</tr>
						<tr v-if="info.organization">
							<td>{{ ii('ORGANIZATION') }}:</td>
							<td>{{ info.organization }}</td>
						</tr>
						<tr v-if="info.idnName">
							<td>{{ ii('IDN_NAME') }}:</td>
							<td>{{ info.idnName }}</td>
						</tr>
						<tr v-if="info.status">
							<td>{{ ii('STATUS') }}:</td>
							<td>
								<template v-if="Array.isArray(info.status)">
									<template v-for="status in info.status">
										{{ status || 'Unknown' }}<br>
									</template>
								</template>
								<template v-else>
									{{ info.status }}
								</template>
							</td>
						</tr>
						<tr v-if="info.nameserver">
							<td>{{ ii('NAME_SERVERS') }}:</td>
							<td>
								<template v-for="(name, index) in info.nameserver">
									{{ index + 1}}) {{ name || 'Unknown' }}<br>
								</template>
							</td>
						</tr>
						<tr v-if="info.ips">
							<td>IPS:</td>
							<td>{{ info.ips }}</td>
						</tr>
						<tr v-if="info.created">
							<td>{{ ii('CREATED') }}:</td>
							<td>{{ info.created }}</td>
						</tr>
						<tr v-if="info.changed">
							<td>{{ ii('CHANGED') }}:</td>
							<td>{{ info.changed }}</td>
						</tr>
						<tr v-if="info.expires">
							<td>{{ ii('EXPIRES') }}:</td>
							<td>{{ info.expires }}</td>
						</tr>
						<tr v-if="info.registered">
							<td>{{ ii('REGISTERED') }}:</td>
							<td>{{ info.registered }}</td>
						</tr>
						<tr v-if="info.dnssec">
							<td>DNSsec:</td>
							<td>{{ info.dnssec }}</td>
						</tr>
						<tr v-if="info.whoisserver">
							<td>{{ ii('WHOIS_SERVER') }}:</td>
							<td>{{ info.whoisserver }}</td>
						</tr>
						<template v-if="info.registrar">
							<tr>
								<td colspan="2" style="text-align: center;">
									{{ ii('REGISTRAR') }}
								</td>
							</tr>
							<tr v-for="(val, key) in info.registrar">
								<template v-if="val">
									<td>{{ key }}</td>
									<td>{{ val }}</td>
								</template>
							</tr>
						</template>
						<template v-if="info.parsedContacts">
							<template v-if="info.contacts['owner']">
								<tr>
									<td colspan="2" style="text-align: center;">
										{{ ii('OWNER_CONTACTS') }}
									</td>
								</tr>
								<tr v-for="(val, key) in info.contacts['owner'][0]">
									<template v-if="val">
										<td>{{ ii(key) }}</td>
										<td>{{ val }}</td>
									</template>
								</tr>
							</template>
							<template v-if="info.contacts['admin']">
								<tr>
									<td colspan="2" style="text-align: center;">
										{{ ii('ADMIN_CONTACTS') }}
									</td>
								</tr>
								<tr v-for="(val, key) in info.contacts['admin'][0]">
									<template v-if="val">
										<td>{{ ii(key) }}</td>
										<td>{{ val }}</td>
									</template>
								</tr>
							</template>
							<template v-if="info.contacts['tech']">
								<tr>
									<td colspan="2" style="text-align: center;">
										{{ ii('TECH_CONTACTS') }}
									</td>
								</tr>
								<tr v-for="(val, key) in info.contacts['tech'][0]">
									<template v-if="val">
										<td>{{ ii(key) }}</td>
										<td>{{ val }}</td>
									</template>
								</tr>
							</template>
						</template>
					</table>
				</div>
				<div v-else-if="app.routeName === 'whoisRaw' && info">
					<pre class="text-left raw-pre">{{ info.rawdata }}</pre>
				</div>

				<div class="page-text-content" v-html="markdownToHtml(app.texts.content)"></div>

			</div>
		</div>
	</div>
</template>


<script>
	import * as moment from 'moment';
	export default {
		data() {
			return {
				isLoading: false,
				info: null,
				prevHost: '',
				error: null
			};
		},
		methods: {
			detectParam(){
				if(!this.app.query.host)
					return;

				let host = this.getDomainFromUrl(this.app.query.host);

				this.app.query.host = host;

				this.getInfo();
			},
			checkHost() {
				if(!this.app.query.host)
					return;

				let host = this.getDomainFromUrl(this.app.query.host);

				this.app.query.host = host;

				if(this.prevHost !== host){
					this.prevHost = host;
					this.refreshQuery();
					this.getInfo();
				}

			},
			async getInfo(){
				this.isLoading = true;

				try{
					this.error = null;
					this.info = await this.get('Domain.getHostInfo', this.app.query);
					this.prepareDates();
				}catch(e){
					this.info = null;
					this.error = e.message;
				}

				this.isLoading = false;
			},
			prepareDates(){
				if(this.info.expires)
					this.info.expires =  this.info.expires + ' (' + moment(this.info.expires, "YYYY-MM-DD").fromNow()+')';
				if(this.info.changed)
					this.info.changed =  this.info.changed + ' (' + moment(this.info.changed, "YYYY-MM-DD").fromNow()+')';
				if(this.info.created)
					this.info.created =  this.info.created + ' (' + moment(this.info.created, "YYYY-MM-DD").fromNow()+')';
			}

		},
		async mounted() {
			this.$refs.tf.focus();
			this.detectParam();
		}
	}
</script>

<style lang="less">

	.raw-pre {
		white-space: pre-wrap;
	}

</style>